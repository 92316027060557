//Add Images for cake
import SpongebobCake from "../assets/SpongebobCake.jpg";
import BarbieCake from "../assets/BarbieCake.jpg";
import CardiacCake from "../assets/CardiacCake.jpg";
import MikeyMouseCake from "../assets/MikeyMouseCake.jpg";

// Array containing details of different projects
export const CakeList = [
  // Define items array outside of the return statement
    {
      src: SpongebobCake,
      altText: "Spongebob Cake",
      caption: "Spongebob Cake",
      key: 1,
    },
    {
      src: BarbieCake,
      altText: "Barbie Cake",
      caption: "Barbie Cake",
      key: 2,
    },
    {
      src: CardiacCake,
      altText: "CardiacCake",
      caption: "CardiacCake",
      key: 3,
    },
    {
      src: MikeyMouseCake,
      altText: "Mikey Mouse Cake",
      caption: "Mikey Mouse Cake",
      key: 4,
    },
  ];
